import requests from "./apiRequest";

export interface Transaction_ID {
    type: number
}

export interface ITransaction_Detail_Items {
    itemType: string
    amountFormatted?: string
    routingNumber?: string
    accountNumber?: string
    serialNumber?: string
    itemSequenceNumber: number
    filename?: string
    imageUrl?: string
    remitterName?: string
    paymentMethod?: string
}

export interface Exception_Transaction_AdditionalFields {
  fieldType: string
  key: string
  value: string
  position?: number
}
export interface Exception_Transaction_AdditionalData {
    exceptionDocumentId: number | null
    exceptionDetails: Exception_Transaction_AdditionalFields[]
    
}

export interface IFields {
    id: number
    fieldType: string
    key: string
    value: string
    position?: number
}
interface UserFields  {
    userId: number
    userName: string
  }
export interface Exception_Transaction_Detail_Res {
    exceptionTransactionId: number
    exceptionTransactionSequenceNo: number
    clientName?: string
    lockboxCode: string
    batchNumber?: string
    amount: number
    depositDate: string
    totalExceptions: number
    outstandingExceptions: number
    completedExceptions: number
    returnedExceptions: number
    escalatedExceptions: number
    rejectedExceptions: number
    transactionStatus: string
    transactionComment?: string
    cutoffTime?: string
    escalateTo?: number
    remitterName?: string
    isPostCutoffTime: boolean
    transactionImages?: string[]
    exceptionDocuments?: Exception_Transaction_AdditionalData []
    escalateToUsers?: UserFields[]
    lockedByUserName?: string
    lockedByUserId?: number | undefined
  }

export interface CreateExceptionTransactionDetail {
  status: string
  comment?: string
  escalatedUserId?: number
  sequenceNumber: string
  exceptionDocuments?: Exception_Transaction_AdditionalData[]
}

export enum TransactionStatus { "Pending", "Escalated", "Returned", "Rejected", "Completed" }

export const getExceptionTransactionDetail = async (ID: number): Promise<Exception_Transaction_Detail_Res> => {
    let result = await requests.get(`/exceptiontransactions/${ID}`);
    return result;
}

export const createExceptionTransaction = async (ID: number, body: CreateExceptionTransactionDetail) => {
      try {
        const data  = await requests.post(`/exceptiontransactions/${ID}?sequenceNumber=${body.sequenceNumber}`, body);
      return data;
      } catch (error) {
        throw error;
      }
  }
